import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AppleIcon from '@mui/icons-material/Apple';
import { useTranslation } from 'react-i18next';
import SpotifyIcon from '../assets/icons/SpotifyIcon';
import YouTubeIcon from '@mui/icons-material/YouTube';
import AmazonMusicIcon from '../assets/icons/AmazonMusicIcon';
import YouTubeMusicIcon from '../assets/icons/YouTubeMusicIcon';
import { Typography } from '@mui/material';

const Music = () => {
  const { t } = useTranslation();
  const breakingBadUrl = 'https://pushforward.online/static/media/music/Breaking-Bad-Preview.mp3';
  const thanosPart6Url = 'https://pushforward.online/static/media/music/Thanos-Part-6-Preview.mp3';
  const lullabyInVUrl = 'https://pushforward.online/static/media/music/Lullaby-in-V-Preview.mp3';

  return (
    <React.Fragment>
      <Box className='banner'>
        <h1>Lullaby in V {t('music.available_now')}</h1>
        <Box className='bannerMusic'>
          <img className='albumCover' src="https://pushforward.online/static/media/images/Lullaby_in_V_400.png" alt='Lullaby in V Album Cover' />
        </Box>
        <Box className='bannerMusic'>
          <audio controls src={lullabyInVUrl} />
        </Box>

        <Button className='streamMusicButton' href='https://music.apple.com/us/album/lullaby-in-v-single/1746205755' target='_blank' variant='contained' startIcon={<AppleIcon />}>
          Music
        </Button>
        <Button className='streamMusicButton' href='https://open.spotify.com/track/34Yd4brlNbgVEedLqjPkJA?si=cb9b2a076e6d4fb2' target='_blank' variant='contained' startIcon={<SpotifyIcon />}>
          Spotify
        </Button>
        <Button className='streamMusicButton' href='https://music.youtube.com/watch?v=ESZg_1jZ7Dk&si=k5VwZX1CQs4E6SL5'target='_blank' variant='contained' startIcon={<YouTubeMusicIcon />}>
          YouTube
        </Button>
        <Button className='streamMusicButton' href='https://www.youtube.com/watch?v=a8Avmaro9Gg' target='_blank' variant='contained' startIcon={<YouTubeIcon />}>
          YouTube
        </Button>
        <Button className='streamMusicButton' href='https://amazon.ca/music/player/tracks/B0D43C8KTB?marketplaceId=A2EUQ1WTGCTBG2&musicTerritory=CA&ref=dm_sh_iqYhhoFcOwSvvLb7jWc3C4NQ3' target='_blank' variant='contained' startIcon={<AmazonMusicIcon />}>
          Amazon
        </Button>
        <Typography>{t('music.also_available_on_favorite_service')}</Typography>
      </Box>

      <Box className='banner'>
        <h1>{t('music.preview_new_music')}</h1>
        <h2>Breaking Bad (Preview)</h2>
        <audio controls src={breakingBadUrl} />
        <h2>Thanos - Part 6 (Preview)</h2>
        <audio controls src={thanosPart6Url} />
      </Box>
    </React.Fragment>
  );
};

export default Music;
